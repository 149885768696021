import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/pages/Login/Login';
import EpisodesPage from './components/pages/Episodes/EpisodesPage';
import EpisodeDetail from './components/pages/Episodes/EpisodeDetail';
import UploadEpisodeView from './components/pages/Episodes/UploadEpisodeView';
import BuildersPage from './components/pages/Builders/BuildersPage';
import BuilderDetail from './components/pages/Builders/BuilderDetail';

import './App.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/episodes" element={<EpisodesPage />} />
        <Route path="/episodes/:episodeId" element={<EpisodeDetail />} />
        <Route path="/episodes/upload" element={<UploadEpisodeView />} />
        <Route path="/builders" element={<BuildersPage />} />
        <Route path="/builders/:builderId" element={<BuilderDetail />} />
        {/* Add more routes as needed */}
      </Routes>
    </Router>
  );
}

export default App;
const InputField = ({ label, type, placeholder, value, onChange }) => (
    <div className="input-group">
        <label>{label}</label>
        <input
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
        />
    </div>
);

export default InputField;
import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import AppLayout from '../../common/AppLayout/AppLayout';
import './EpisodeDetail.css';
import CollapsibleSection from '../../common/Episodes/CollapsibleSection';
import { ENDPOINTS } from '../../../config';

const EpisodeDetail = () => {
  const [episodeData, setEpisodeData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const { episodeId } = useParams();
  const navigate = useNavigate();

  const fetchEpisodeDetail = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) {
        throw new Error('No access token found');
      }
      const response = await fetch(`${ENDPOINTS.EPISODE_DETAIL}${episodeId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        credentials: 'include',
      });

      if (response.status === 401) {
        localStorage.removeItem('accessToken');
        navigate('/login');
        return;
      }

      if (!response.ok) {
        throw new Error('Failed to fetch episode details');
      }

      const data = await response.json();
      setEpisodeData(data.processed_data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching episode details:', error);
      setError('Failed to load episode details. Please try again later.');
      setLoading(false);
      if (error.message === 'No access token found') {
        navigate('/login');
      }
    }
  }, [episodeId, navigate]);

  useEffect(() => {
    fetchEpisodeDetail();
  }, [fetchEpisodeDetail]);

  const handleReturnEpisodesClick = () => {
    navigate('/episodes');
  };

  const renderContent = (value) => {
    if (Array.isArray(value)) {
      return value.map((item, index) => (
        <ReactMarkdown key={index}>{item}</ReactMarkdown>
      ));
    }
    return <ReactMarkdown>{value}</ReactMarkdown>;
  };

  return (
    <AppLayout
      title_page={`Episode Detail`}
      button_page={'Back to Episodes'}
      onButtonClick={handleReturnEpisodesClick}
      currentView="episode_detail"
      episodeId={episodeId}
    >
      <div className="episode-detail">
        {loading && <div className="loading">Loading episode details...</div>}
        {error && <div className="error">{error}</div>}
        {episodeData && (
          <>
            {episodeData.custom_blocks && Object.entries(episodeData.custom_blocks).map(([key, value]) => (
              <CollapsibleSection
                key={key}
                title={key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ')}
                type="custom_blocks"
              >
                <div className="section-content">
                  {renderContent(value)}
                </div>
              </CollapsibleSection>
            ))}
            {episodeData.building_blocks && Object.entries(episodeData.building_blocks).map(([key, value]) => (
              <CollapsibleSection
                key={key}
                title={key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ')}
                type="buildin_blocks"
              >
                <div className="section-content">
                  {renderContent(value)}
                </div>
              </CollapsibleSection>
            ))}
          </>
        )}
        {!loading && !error && !episodeData && (
          <div className="no-data">No episode data available.</div>
        )}
      </div>
    </AppLayout>
  );
};

export default EpisodeDetail;
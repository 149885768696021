export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const ENDPOINTS = {
  LOGIN: `${API_BASE_URL}/api/lvlcast/login`,
  EPISODES: `${API_BASE_URL}/api/lvlcast/episodes`,
  EPISODE_DETAIL: `${API_BASE_URL}/api/lvlcast/episodes/get_processed_data/`,
  EPISODE_CREATE: `${API_BASE_URL}/api/lvlcast/episodes/create`,
  BUILDERS: `${API_BASE_URL}/api/lvlcast/builders`,
  BUILDER_CREATE: `${API_BASE_URL}/api/lvlcast/builders/create`,
  BUILDER_UPDATE: `${API_BASE_URL}/api/lvlcast/builders/update/`,
  BUILDER_DETAIL: `${API_BASE_URL}/api/lvlcast/builders/get_builder_data/`,
  BUILDER_APPLY_ALL: `${API_BASE_URL}/api/lvlcast/builders/apply/`
};
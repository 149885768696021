import './SidebarButton.css';

const SidebarButton = ({ icon, altText, onClick }) => {
    return (
        <button className="sidebar-btn" onClick={onClick}>
            <img src={icon} alt={altText} />
        </button>
    );
};

export default SidebarButton;
import { useNavigate } from 'react-router-dom';
import SidebarButton from './SidebarButton';
import './Sidebar.css';

// Import SVG icons
import dashboardIcon from '../../../assets/icons/icon-dashboard.svg';
import episodesIcon from '../../../assets/icons/icon-episodes.svg';
import buildersIcon from '../../../assets/icons/icon-builders2.svg';

const Sidebar = () => {
    const navigate = useNavigate();
    return (
        <div className="sidebar">
            <SidebarButton icon={dashboardIcon} altText="Dashboard" onClick={() => navigate('/')} />
            <SidebarButton icon={episodesIcon} altText="Episodes" onClick={() => navigate('/episodes')} />
            <SidebarButton icon={buildersIcon} altText="Builders" onClick={() => navigate('/builders')} />
            {/* Add more SidebarButton components as needed */}
        </div>
    );
};

export default Sidebar;
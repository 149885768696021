import React from 'react';
import { useNavigate } from 'react-router-dom';
import AppLayout from '../../common/AppLayout/AppLayout';
import { ENDPOINTS } from '../../../config';
import './BuildersPage.css';
import BuildersList from './BuildersList'; // New component to be created

const BuildersPage = () => {
    const navigate = useNavigate();

    const handleAddBuilderClick = async () => {
        try {
            const accessToken = localStorage.getItem('accessToken');
            if (!accessToken) {
                throw new Error('No access token found');
            }

            const newBuilder = {
                builder_name: "New Builder",
                builder_content: "Default template content: {{summary}}",
                auto_execution: false
            };

            const response = await fetch(ENDPOINTS.BUILDER_CREATE, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
                body: JSON.stringify(newBuilder),
                credentials: 'include',
            });

            if (response.status === 401) {
                localStorage.removeItem('accessToken');
                navigate('/login');
                return;
            }

            if (!response.ok) {
                throw new Error('Failed to create builder');
            }

            const data = await response.json();
            // Navigate to the newly created builder's detail page
            navigate(`/builders/${data.builder.builder_id}`);
        } catch (error) {
            console.error('Error creating builder:', error);
            // You might want to show an error message to the user here
        }
    };

    return (
        <AppLayout 
            title_page={"Builders"} 
            button_page={"Add builder"} 
            onButtonClick={handleAddBuilderClick}
            currentView="table"
        >
            <BuildersList />
        </AppLayout>
    );
};

export default BuildersPage;
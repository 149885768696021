import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppLayout from '../../common/AppLayout/AppLayout';
import UploadBox from '../../common/Episodes/UploadBox';
import './UploadEpisodeView.css'; // We'll create this file for the new styles

const UploadEpisodeView = () => {
    const navigate = useNavigate();
    const [uploadStatus, setUploadStatus] = useState(null); // 'success', 'fail', or null

    const handleBackClick = () => {
        navigate('/episodes');
    };

    const handleSubmit = (data, error) => {
        if (error) {
            console.error('Upload failed:', error);
            setUploadStatus('fail');
        } else {
            console.log('Upload successful:', data);
            setUploadStatus('success');
        }
    };

    const handleTryAgain = () => {
        setUploadStatus(null);
    };

    const handleStatusAction = () => {
        if (uploadStatus === 'success') {
            handleBackClick();
        } else {
            handleTryAgain();
        }
    };

    return (
        <AppLayout 
            title_page="Upload Episode"
            button_page="Back to Episodes"
            onButtonClick={handleBackClick}
            currentView="table"
        >
            <div className="upload-episode-view">
                <UploadBox onSubmit={handleSubmit} />
                {uploadStatus && (
                    <div className={`upload-status-popup ${uploadStatus}`}>
                        <h3>{uploadStatus === 'success' ? 'Upload Successful!' : 'Upload Failed'}</h3>
                        <p>
                            {uploadStatus === 'success' 
                                ? 'The content will be available soon.' 
                                : 'Please try again.'}
                        </p>
                        <button 
                            onClick={handleStatusAction}
                            className="status-action-btn"
                        >
                            {uploadStatus === 'success' ? 'Back to Episodes' : 'Try Again'}
                        </button>
                    </div>
                )}
            </div>
        </AppLayout>
    );
};

export default UploadEpisodeView;
import { useState } from 'react';
import Title from '../../common/Title/Title';
import Sidebar from '../Sidebar/Sidebar'; // Adjust the import path as needed
import './AppLayout.css'; // Optional: Add specific styles for layout

import showSidebarIcon from '../../../assets/icons/show-sidebar-icon.svg';
import hideSidebarIcon from '../../../assets/icons/hide-sidebar-icon.svg'; // Import the hide icon

const Layout = ({ title_page, button_page, onButtonClick, currentView, children, episodeId }) => {
    const [isSidebarVisible, setIsSidebarVisible] = useState(true);

    // Function to toggle the sidebar visibility
    const toggleSidebar = () => {
        setIsSidebarVisible(!isSidebarVisible);
    };

    return (
        <div className="page-container">
            <div className="background-layer"></div>
            <div className="content-wrapper">
                <button onClick={toggleSidebar} className="toggle-sidebar-btn">
                    <img 
                        src={isSidebarVisible ? hideSidebarIcon : showSidebarIcon} 
                        alt="Toggle Sidebar" 
                    />
                </button>
                <div className="layout">
                    <div className={`sidebar-wrapper ${isSidebarVisible ? 'visible' : 'hidden'}`}>
                        <Title />
                        <Sidebar />
                    </div>
                    <div className={`content-area ${isSidebarVisible ? '' : 'full-width'}`}>
                        <div className={`background-wrapper ${!isSidebarVisible ? 'hidden-sidebar' : ''}`}>
                            <div className="background-episode-page">
                                {(currentView === 'table' || currentView === 'episode_detail') && button_page && onButtonClick && (
                                    <div className="page-title">
                                        <div className="title-wrapper">
                                            <h1>{title_page}</h1>
                                            {episodeId && (
                                                <span className="episode-id-box">{episodeId}</span>
                                            )}
                                        </div>
                                        <button onClick={onButtonClick} className="page-button">
                                            {button_page}
                                        </button>
                                    </div>
                                )}
                                
                                {children} {/* This will render the content of the specific page */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Layout;

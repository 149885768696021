import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AppLayout from '../../common/AppLayout/AppLayout';
import './BuilderDetail.css';
import placeholderIcon from '../../../assets/icons/icon-placeholder.svg';
import { ENDPOINTS } from '../../../config';
import LoadingSpinner from '../../common/LoadingSpinner';

const BuilderDetail = () => {
    const { builderId } = useParams();
    const navigate = useNavigate();
    const [builder, setBuilder] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [cursorPosition, setCursorPosition] = useState({ top: 0, left: 0 });
    const [showDynamicButton, setShowDynamicButton] = useState(false);
    const textareaRef = useRef(null);
    const [editableTitle, setEditableTitle] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [textareaHeight, setTextareaHeight] = useState('auto');
    const resizeTimeoutRef = useRef(null);

    useEffect(() => {
        fetchBuilder();
    }, [builderId]);

    const fetchBuilder = async () => {
        setIsLoading(true);
        try {
            const accessToken = localStorage.getItem('accessToken');
            if (!accessToken) {
                throw new Error('No access token found');
            }

            const response = await fetch(`${ENDPOINTS.BUILDER_DETAIL}${builderId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
                credentials: 'include',
            });

            if (response.status === 401) {
                localStorage.removeItem('accessToken');
                navigate('/login');
                return;
            }

            if (!response.ok) {
                throw new Error('Failed to fetch builder');
            }

            const data = await response.json();
            setBuilder(data.builder);
            setEditableTitle(data.builder.builder_name);
        } catch (error) {
            console.error('Error fetching builder:', error);
            setError('Failed to load builder. Please try again later.');
            if (error.message === 'No access token found') {
                navigate('/login');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleChange = useCallback((field, value) => {
        setBuilder(prev => ({ ...prev, [field]: value }));
    }, []);

    const handleTitleChange = (e) => {
        setEditableTitle(e.target.value);
    };

    const handleSave = async () => {
        setIsSaving(true);
        try {
            const accessToken = localStorage.getItem('accessToken');
            if (!accessToken) {
                throw new Error('No access token found');
            }

            const response = await fetch(`${ENDPOINTS.BUILDER_UPDATE}${builderId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
                body: JSON.stringify({
                    builder_name: editableTitle,
                    builder_content: builder.builder_content,
                    auto_execution: builder.auto_execution,
                }),
                credentials: 'include',
            });

            if (response.status === 401) {
                localStorage.removeItem('accessToken');
                navigate('/login');
                return;
            }

            if (!response.ok) {
                throw new Error('Failed to update builder');
            }

            const updatedBuilder = await response.json();
            setBuilder(updatedBuilder.builder);
            setIsEditing(false);
            console.log('Builder updated successfully:', updatedBuilder);
        } catch (error) {
            console.error('Error updating builder:', error);
            setError('Failed to update builder. Please try again later.');
        } finally {
            setIsSaving(false);
        }
    };

    const handleBack = () => {
        navigate('/builders');
    };

    const placeholderText = `Configure your builder here...

Available building blocks:
{{audience_questions}} - Thought-provoking questions derived from the episode content
{{content_suggestions}} - Curated ideas and topics for further exploration
{{deeper_content}} - In-depth analysis and extended information
{{keywords}} - Essential terms and concepts from the episode
{{summary}} - Concise overview of main points and key takeaways
{{timestamps}} - Chronological markers for significant moments
{{title_recommendations}} - Creative and SEO-optimized title options
{{guests_names}} - The name of the guest featured in the episode
{{guests_info}} - A brief description or bio of the guest

Use the following summary and timecode to generate this episode's YouTube description:
{{summary}}
{{timestamps}}

Here is an example of the expected output:
ADD EXAMPLE HERE
`;

    const buildingBlocks = {
        "{{audience_questions}}": "Thought-provoking questions derived from the episode content to engage listeners",
        "{{content_suggestions}}": "Curated ideas and topics for further exploration based on the episode",
        "{{deeper_content}}": "In-depth analysis and extended information expanding on key episode topics",
        "{{keywords}}": "Essential terms and concepts that capture the core themes of the episode",
        "{{summary}}": "Concise overview highlighting the main points and key takeaways from the episode",
        "{{timestamps}}": "Chronological markers for significant moments and topics discussed in the episode",
        "{{title_recommendations}}": "Creative and SEO-optimized title options reflecting the episodes content",
        "{{guests_names}}": "The name of the guest featured in the episode",
        "{{guests_info}}": "A brief description or bio of the guest featured in the episode"
    };

    const handleTextareaChange = useCallback((e) => {
        const newContent = e.target.value;
        handleChange('builder_content', newContent);
        
        // Validate input
        const isValidInput = Object.keys(buildingBlocks).some(block => newContent.includes(block));
        setIsValid(isValidInput);

        // Debounce the resize operation
        if (resizeTimeoutRef.current) {
            clearTimeout(resizeTimeoutRef.current);
        }

        resizeTimeoutRef.current = setTimeout(() => {
            const scrollHeight = e.target.scrollHeight;
            setTextareaHeight(`${scrollHeight}px`);
        }, 100);

        if (isEditing) {
            const textarea = e.target;
            const cursorPos = textarea.selectionStart;
            const textBeforeCursor = textarea.value.substring(0, cursorPos);
            const lineHeight = parseInt(getComputedStyle(textarea).lineHeight);
            const lines = textBeforeCursor.split('\n');
            const currentLine = lines.length;
            
            // Calculate the position of the cursor relative to the visible area
            const scrollTop = textarea.scrollTop;
            const clientHeight = textarea.clientHeight;
            const cursorTop = (currentLine - 1) * lineHeight - scrollTop;
            
            // Ensure the button stays within the visible area
            const maxTop = clientHeight - 40; // 40 is the height of the button
            const top = Math.min(Math.max(cursorTop, 0), maxTop);

            setCursorPosition({ top, left: -50 });
            setShowDynamicButton(true);
        } else {
            setShowDynamicButton(false);
        }

        // Smooth scroll to bottom if near the end
        if (e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 100) {
            setTimeout(() => {
                e.target.scrollTop = e.target.scrollHeight;
            }, 0);
        }
    }, [handleChange, isEditing, buildingBlocks]);

    useEffect(() => {
        return () => {
            if (resizeTimeoutRef.current) {
                clearTimeout(resizeTimeoutRef.current);
            }
        };
    }, []);

    const handleDynamicButtonClick = useCallback(() => {
        setShowSuggestions(prev => !prev);
    }, []);

    const insertBuildingBlock = useCallback((block) => {
        const textarea = textareaRef.current;
        const { selectionStart, selectionEnd } = textarea;
        const currentContent = textarea.value;
        const newContent = currentContent.slice(0, selectionStart) + block + currentContent.slice(selectionEnd);
        handleChange('builder_content', newContent);
        setShowSuggestions(false);
        
        setTimeout(() => {
            textarea.focus();
            textarea.setSelectionRange(selectionStart + block.length, selectionStart + block.length);
        }, 0);
    }, [handleChange]);

    const handleAutoExecuteChange = (e) => {
        const newValue = e.target.checked;
        setBuilder(prev => ({ ...prev, auto_execution: newValue }));
    };

    const highlightBuildingBlocks = (content) => {
        const blocks = Object.keys(buildingBlocks);
        let highlightedContent = content;
        blocks.forEach(block => {
            const regex = new RegExp(block, 'g');
            highlightedContent = highlightedContent.replace(regex, `<span class="highlight-block">${block}</span>`);
        });
        return highlightedContent;
    };

    if (isLoading) {
        return (
            <AppLayout 
                title_page="Builder Detail" 
                currentView="table"
            >
                <LoadingSpinner />
            </AppLayout>
        );
    }

    if (error) {
        return (
            <AppLayout 
                title_page="Builder Detail" 
                currentView="table"
            >
                <div className="error">{error}</div>
            </AppLayout>
        );
    }

    if (!builder) return null;

    return (
        <AppLayout
            title_page={isEditing ? 
                <input 
                    type="text" 
                    value={editableTitle} 
                    onChange={handleTitleChange} 
                    className="editable-title"
                /> : 
                builder.builder_name
            }
            button_page={isEditing ? (isSaving ? "Saving..." : "Save Changes") : "Edit Builder"}
            onButtonClick={isEditing ? handleSave : () => setIsEditing(true)}
            currentView="table"
        >
            <div className="builder-detail-container">
                {isSaving && (
                    <div className="saving-overlay">
                        <LoadingSpinner />
                        <p>Saving changes...</p>
                    </div>
                )}
                <div className="builder-section">
                    <div className="builder-auto-execute">
                        <label className="switch">
                            <input
                                type="checkbox"
                                checked={builder.auto_execution}
                                onChange={handleAutoExecuteChange}
                                disabled={!isEditing}
                            />
                            <span className="slider round"></span>
                        </label>
                        <span>Auto-execute on new episodes</span>
                    </div>
                </div>
                <div className="builder-section">
                    <h3>Prompt</h3>
                    <div className="textarea-wrapper">
                        {isEditing && showDynamicButton && (
                            <button
                                className={`dynamic-button ${showSuggestions ? 'active' : ''}`}
                                onClick={handleDynamicButtonClick}
                                style={{ top: `${cursorPosition.top}px` }}
                            >
                                <img src={placeholderIcon} alt="Insert Value" />
                            </button>
                        )}
                        {isEditing ? (
                            <textarea
                                ref={textareaRef}
                                value={builder.builder_content}
                                onChange={handleTextareaChange}
                                onKeyUp={handleTextareaChange}
                                onClick={handleTextareaChange}
                                placeholder={placeholderText}
                                className={`builder-input builder-content ${isValid ? 'valid' : 'invalid'}`}
                                style={{ height: textareaHeight }}
                            />
                        ) : (
                            <div 
                                className="builder-input builder-content readonly-content"
                                dangerouslySetInnerHTML={{ __html: highlightBuildingBlocks(builder.builder_content) }}
                            >
                            </div>
                        )}
                        {showSuggestions && (
                            <div className="building-blocks-suggestions" style={{
                                top: `${cursorPosition.top + 40}px`,
                                left: '10px',
                            }}>
                                {Object.entries(buildingBlocks).map(([block, description]) => (
                                    <div 
                                        key={block} 
                                        className="suggestion-item"
                                        onClick={() => insertBuildingBlock(block)}
                                    >
                                        <span className="building-block">{block}</span>
                                        <span className="description">{description}</span>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                {!isEditing && (
                    <button onClick={handleBack} className="back-button">Back to Builders</button>
                )}
            </div>
        </AppLayout>
    );
};

export default BuilderDetail;
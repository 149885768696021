import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputField from '../../common/InputField';
import { ENDPOINTS } from '../../../config';
import './Login.css'

const socialPlatforms = [
    { id: 'google', label: 'Google', logo: '/google-logo.png', className: 'google' },
    { id: 'github', label: 'GitHub', logo: '/github-logo.png', className: 'github' },
    // { id: 'facebook', label: 'Facebook', logo: '/icon_facebook.png', className: 'facebook' }, // Make sure you have the correct file for Facebook if needed
];

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');

        try {
            const body = JSON.stringify({ email, password });
            const response = await fetch(ENDPOINTS.LOGIN, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: body,
                credentials: 'include',
            });

            if (!response.ok) {
                const errorData = await response.text();
                throw new Error(errorData || 'Login failed');
            }

            const data = await response.json();
            localStorage.setItem('accessToken', data.access_token);
            localStorage.setItem('userId', data.user_id);
            navigate('/episodes');
        } catch (error) {
            setError('Login failed. Please try again.');
            console.error('Login error:', error);
        }
    };

    return (
        <div className="login-container">
            <h1 className="title">LVLCAST</h1>
            <div className="login-box">
                <h2>Login</h2>
                {error && <p className="error-message">{error}</p>}
                <form onSubmit={handleSubmit}>
                    <InputField
                        label="Email"
                        type="email"
                        placeholder="username@gmail.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <InputField
                        label="Password"
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <a href="/forgot-password" className="forgot-password">Forgot Password?</a>
                    <button type="submit" className="btn">Sign in</button>
                </form>
                <p>or continue with</p>
                <div className="social-login">
                    {socialPlatforms.map(({id, label, logo, className}) => (
                        <button key={id} className={`social-btn ${className}`}>
                            <img src={logo} alt={`${label} logo`} className="social-logo" />
                        </button>
                    ))}
                </div>
                <p className="register-text">
                    Don't have an account yet? <span className="register-link">Register for free</span>
                </p>
            </div>
        </div>
    );
};

export default Login;
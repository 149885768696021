import { useState, useRef } from 'react';
import './CollapsibleSection.css';

const CollapsibleSection = ({ title, children, type }) => {
    const [isOpen, setIsOpen] = useState(false);
    const contentRef = useRef(null);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    const copyToClipboard = () => {
        const textToCopy = contentRef.current.innerText;
        navigator.clipboard.writeText(textToCopy);
        return textToCopy;
    };

    return (
        <div className={`collapsible-section ${type}`}>
            <div 
                className={`collapsible-header ${type}`} 
                onClick={toggleOpen}
                role="button"
                aria-expanded={isOpen}
                aria-controls={`section-${title}`}
            >
                <h3>{title}</h3>
                <span className={`toggle-icon ${isOpen ? 'open' : ''}`}>
                    {isOpen ? '−' : '+'}
                </span>
            </div>
            <div
                id={`section-${title}`}
                ref={contentRef}
                className={`collapsible-content ${isOpen ? 'open' : ''}`}
                style={{
                    maxHeight: isOpen ? `${contentRef.current.scrollHeight}px` : '0px',
                }}
            >
                {children}
                <button className="copy-button" onClick={copyToClipboard}>
                    Copy
                </button>
            </div>
        </div>
    );
};

export default CollapsibleSection;
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppLayout from '../../common/AppLayout/AppLayout';
import TableView from './TableView';
import './EpisodesPage.css';

const EpisodesPage = () => {
    const navigate = useNavigate();
    
    const handleAddEpisodeClick = () => {
        navigate('/episodes/upload');
    };

    return (
        <AppLayout title_page={"Episodes"} 
        button_page={"Add episode"} 
        onButtonClick={handleAddEpisodeClick}
        currentView="table">
            <TableView />
        </AppLayout>
    );
};

export default EpisodesPage;

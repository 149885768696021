import { useState, useEffect, useCallback, useMemo } from 'react';
import debounce from 'lodash/debounce';
import './UploadBox.css';
import { ENDPOINTS } from '../../../config';

const UploadBox = ({ onSubmit }) => {
    const [file, setFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [formData, setFormData] = useState({
        language_code: '',
        episode_number: '',
        podcast_name: '',
        host_name: '',
        guests: []
    });
    const [isFormValid, setIsFormValid] = useState(false);
    const [guestInputs, setGuestInputs] = useState({});

    const updateFormData = useCallback((field, value) => {
        setFormData(prev => ({
            ...prev,
            [field]: value
        }));
    }, []);

    const debouncedUpdateGuest = useMemo(
        () => debounce((id, field, value) => {
            updateFormData('guests', formData.guests.map(guest => 
                guest.id === id ? { ...guest, [field]: value } : guest
            ));
        }, 300),
        [formData.guests, updateFormData]
    );

    const handleGuestInputChange = useCallback((id, field, value) => {
        setGuestInputs(prev => ({
            ...prev,
            [id]: {
                ...prev[id],
                [field]: value
            }
        }));
        debouncedUpdateGuest(id, field, value);
    }, [debouncedUpdateGuest]);

    const addGuest = useCallback(() => {
        if (formData.guests.length < 3) {
            const newGuest = { id: Date.now(), name: '', description: '' };
            updateFormData('guests', [...formData.guests, newGuest]);
            setGuestInputs(prev => ({
                ...prev,
                [newGuest.id]: { name: '', description: '' }
            }));
        }
    }, [formData.guests, updateFormData]);

    const removeGuest = useCallback((id) => {
        updateFormData('guests', formData.guests.filter(guest => guest.id !== id));
        setGuestInputs(prev => {
            const newInputs = { ...prev };
            delete newInputs[id];
            return newInputs;
        });
    }, [formData.guests, updateFormData]);

    useEffect(() => {
        const requiredFieldsFilled = 
            formData.language_code.trim() !== '' &&
            formData.episode_number !== '' &&
            formData.podcast_name.trim() !== '' &&
            formData.host_name.trim() !== '';
        
        const guestsValid = formData.guests.every(guest => 
            guest.name.trim() !== '' || guest.description.trim() !== ''
        );
        
        setIsFormValid(requiredFieldsFilled && guestsValid && file !== null);
    }, [formData, file]);

    const simulateUploadTime = (fileSize) => {
        const uploadSpeed = 1 * 1024 * 1024; // 1 MB/s in bytes
        const estimatedTime = (fileSize / uploadSpeed) * 1000; // Convert to milliseconds
        const randomFactor = Math.random() * 0.3 + 0.85; // Random factor between 0.85 and 1.15
        return Math.min(estimatedTime * randomFactor, 30000); // Cap at 30 seconds
    };

    const simulateUploadProgress = (duration, abortController) => {
        let progress = 0;
        const interval = setInterval(() => {
            if (abortController.signal.aborted) {
                clearInterval(interval);
                return;
            }
            progress += 1;
            setUploadProgress(Math.min(progress, 95)); // Cap at 95%
            if (progress >= 95) {
                clearInterval(interval);
            }
        }, duration / 100);
        return interval;
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const droppedFile = event.dataTransfer.files[0];
        setFile(droppedFile);
        setUploadSuccess(false);
    };

    const handleFileSelect = (event) => {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
        setUploadSuccess(false);
    };

    const removeFile = () => {
        setFile(null);
        setUploadProgress(0);
        setUploading(false);
        setUploadSuccess(false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        updateFormData(name, value); // Remove trim() to allow spaces
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (file && isFormValid) {
            try {
                const formDataToSend = new FormData();
                formDataToSend.append('file', file);
                
                // Append updated form fields
                formDataToSend.append('language_code', formData.language_code);
                formDataToSend.append('episode_number', formData.episode_number);
                formDataToSend.append('podcast_name', formData.podcast_name);
                formDataToSend.append('host_name', formData.host_name);
                formDataToSend.append('guests', JSON.stringify(formData.guests));

                setUploading(true);
                setUploadProgress(0);

                const accessToken = localStorage.getItem('accessToken');
                if (!accessToken) {
                    throw new Error('No access token found');
                }

                const uploadDuration = simulateUploadTime(file.size);
                let progressInterval;

                const controller = new AbortController();
                const signal = controller.signal;

                progressInterval = setInterval(() => {
                    setUploadProgress(prev => {
                        if (prev >= 95) {
                            clearInterval(progressInterval);
                            return 95;
                        }
                        return prev + 1;
                    });
                }, uploadDuration / 100);

                const response = await fetch(ENDPOINTS.EPISODE_CREATE, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                    body: formDataToSend,
                    credentials: 'include',
                    signal: signal
                });

                clearInterval(progressInterval);
                setUploadProgress(100);

                if (response.status === 401) {
                    localStorage.removeItem('accessToken');
                    throw new Error('Unauthorized');
                }

                if (!response.ok) {
                    throw new Error('Failed to upload episode');
                }

                const data = await response.json();
                setUploading(false);
                setUploadSuccess(true);
                onSubmit(data);
            } catch (error) {
                console.error('Error uploading episode:', error);
                setUploading(false);
                setUploadProgress(0);
                onSubmit(null, error);
                if (error.message === 'No access token found' || error.message === 'Unauthorized') {
                    // Handle unauthorized access
                }
            }
        }
    };

    return (
        <div className="upload-box">
            <h2>Add a new episode</h2>
            <div className="form-content">
                <div className="form-fields-container">
                    <div className="form-fields">
                        <div className="form-group">
                            <label htmlFor="language_code">Language Code:</label>
                            <select
                                id="language_code"
                                name="language_code"
                                value={formData.language_code}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="">Select language</option>
                                <option value="en">English</option>
                                <option value="fr">French</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="episode_number">Episode Number:</label>
                            <input
                                type="number"
                                id="episode_number"
                                name="episode_number"
                                value={formData.episode_number}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="podcast_name">Podcast Name:</label>
                            <input
                                type="text"
                                id="podcast_name"
                                name="podcast_name"
                                value={formData.podcast_name}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="host_name">Host Name:</label>
                            <input
                                type="text"
                                id="host_name"
                                name="host_name"
                                value={formData.host_name}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="guests-section">
                            {formData.guests.map((guest) => (
                                <div key={guest.id} className="guest-form">
                                    <div className="form-group">
                                        <label htmlFor={`guestName-${guest.id}`}>Guest Name:</label>
                                        <input
                                            type="text"
                                            id={`guestName-${guest.id}`}
                                            value={guestInputs[guest.id]?.name ?? guest.name}
                                            onChange={(e) => handleGuestInputChange(guest.id, 'name', e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor={`guestDescription-${guest.id}`}>Guest Description:</label>
                                        <input
                                            type="text"
                                            id={`guestDescription-${guest.id}`}
                                            value={guestInputs[guest.id]?.description ?? guest.description}
                                            onChange={(e) => handleGuestInputChange(guest.id, 'description', e.target.value)}
                                        />
                                    </div>
                                    <button type="button" className="remove-guest-btn" onClick={() => removeGuest(guest.id)}>Remove Guest</button>
                                </div>
                            ))}
                            {formData.guests.length < 3 && (
                                <button type="button" className="add-guest-btn" onClick={addGuest}>+ Add Guest</button>
                            )}
                        </div>
                    </div>
                </div>
                <div className="upload-area-container">
                    <div
                        className={`upload-area ${file ? 'file-loaded' : ''}`}
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={handleDrop}
                    >
                        {uploading ? (
                            <div className="progress-bar">
                                <div className="progress" style={{ width: `${uploadProgress}%` }}>
                                    {uploadProgress}%
                                </div>
                            </div>
                        ) : file ? (
                            <div className="file-info">
                                <p>{file.name}</p>
                                <button type="button" className="remove-file-btn" onClick={removeFile}>✕</button>
                            </div>
                        ) : (
                            <>
                                <p>Drag & drop audio file here, or <span className="browse-link">browse</span></p>
                                <p className="formats">Supported formats: .wav, .mp3, up to 500Mb</p>
                                <input type="file" onChange={handleFileSelect} style={{ display: 'none' }} id="file-input" />
                                <label htmlFor="file-input" className="file-input-label">Browse</label>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <button 
                type="submit" 
                className={`upload-btn ${isFormValid ? 'ready' : 'not-ready'}`} 
                onClick={handleSubmit}
                disabled={!isFormValid || uploading}
            >
                {uploading ? `Uploading... ${uploadProgress}%` : 'Upload Episode'}
            </button>
        </div>
    );
};

export default UploadBox;
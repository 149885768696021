import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../common/LoadingSpinner';
import { ENDPOINTS } from '../../../config';
import threeDots from '../../../assets/icons/icon-three-dots.svg';

const BuildersList = () => {
    const navigate = useNavigate();
    const [builders, setBuilders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeMenu, setActiveMenu] = useState(null);
    const [showOverlay, setShowOverlay] = useState(false);
    const [applyingBuilderId, setApplyingBuilderId] = useState(null);
    const [applyStatus, setApplyStatus] = useState(null);
    const [appliedBuilders, setAppliedBuilders] = useState(new Set());

    const fetchBuilders = useCallback(async () => {
        try {
            const accessToken = localStorage.getItem('accessToken');
            if (!accessToken) {
                console.log('No access token found, redirecting to login');
                navigate('/login', { replace: true });
                return;
            }

            console.log('Fetching builders...');
            const response = await fetch(ENDPOINTS.BUILDERS, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
                credentials: 'include',
            });
            
            console.log('Response status:', response.status);
            
            if (response.status === 401) {
                console.log('401 error detected, removing token and redirecting to login');
                localStorage.removeItem('accessToken');
                navigate('/login', { replace: true });
                return;
            }

            if (!response.ok) {
                throw new Error(`Failed to fetch builders: ${response.status}`);
            }

            const data = await response.json();
            setBuilders(data.builders || []);
            setLoading(false);
        } catch (error) {
            console.error('Error in fetchBuilders:', error);
            setError('Failed to load builders. Please try again later.');
            setLoading(false);
        }
    }, [navigate]);

    useEffect(() => {
        fetchBuilders();
    }, [fetchBuilders]);

    const handleBuilderClick = (builderId) => {
        navigate(`/builders/${builderId}`);
    };

    const toggleMenu = (e, builderId) => {
        e.stopPropagation();
        if (activeMenu === builderId) {
            setActiveMenu(null);
            setShowOverlay(false);
        } else {
            setActiveMenu(builderId);
            setShowOverlay(true);
        }
    };

    const handleOverlayClick = () => {
        setActiveMenu(null);
        setShowOverlay(false);
    };

    const handleApplyToAllEpisodes = async (builder) => {
        setApplyingBuilderId(builder.builder_id);
        setApplyStatus(null);
        try {
            const accessToken = localStorage.getItem('accessToken');
            if (!accessToken) {
                throw new Error('No access token found');
            }

            const response = await fetch(`${ENDPOINTS.BUILDER_APPLY_ALL}${builder.builder_id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
                credentials: 'include',
            });

            if (response.status === 401) {
                localStorage.removeItem('accessToken');
                navigate('/login');
                return;
            }

            if (!response.ok) {
                throw new Error('Failed to apply builder to all episodes');
            }

            setApplyStatus('success');
            setAppliedBuilders(prev => new Set(prev).add(builder.builder_id));
        } catch (error) {
            console.error('Error applying builder to all episodes:', error);
            setApplyStatus('error');
        } finally {
            setTimeout(() => {
                setApplyingBuilderId(null);
                setApplyStatus(null);
            }, 3000);
        }
    };

    if (loading) return <div className="loading">Loading builders...</div>;
    if (error) return <div className="error">{error}</div>;

    return (
        <div className="builders-container">
            {builders.length === 0 ? (
                <div className="no-builders-message">
                    No builders available. Click "Add builder" to create one.
                </div>
            ) : (
                <div className="builders-list">
                    {builders.map((builder) => (
                        <div key={builder.builder_id} className="builder-item-wrapper">
                            <div className="builder-item-container">
                                <button 
                                    className="builder-item"
                                    onClick={() => handleBuilderClick(builder.builder_id)}
                                >
                                    <h3>{builder.builder_name}</h3>
                                </button>
                                <button 
                                    className="menu-button" 
                                    onClick={(e) => toggleMenu(e, builder.builder_id)}
                                >
                                    <img src={threeDots} alt="Menu" />
                                </button>
                            </div>
                            {activeMenu === builder.builder_id && (
                                <div className="context-menu">
                                    {!appliedBuilders.has(builder.builder_id) && (
                                        <button onClick={() => handleApplyToAllEpisodes(builder)}>Apply to All Episodes</button>
                                    )}
                                </div>
                            )}
                            {applyingBuilderId === builder.builder_id && (
                                <div className="applying-overlay">
                                    {!applyStatus && (
                                        <>
                                            <LoadingSpinner />
                                            <p>Applying builder to all episodes...</p>
                                        </>
                                    )}
                                    {applyStatus === 'success' && (
                                        <p className="apply-status success">Successfully applied to all episodes!</p>
                                    )}
                                    {applyStatus === 'error' && (
                                        <p className="apply-status error">Failed to apply to all episodes. Please try again.</p>
                                    )}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}
            {showOverlay && <div className="overlay" onClick={handleOverlayClick}></div>}
        </div>
    );
};

export default BuildersList;
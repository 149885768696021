import './Title.css';

const Title = () => {
    return (
        <div className="levelcast-title">
            LVL<br />CAST
        </div>
    );
};

export default Title;       
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ENDPOINTS } from '../../../config';
import './TableView.css';

const TableView = () => {
    const navigate = useNavigate();
    const [episodes, setEpisodes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchEpisodes();
    }, []);

    const fetchEpisodes = async () => {
        try {
            const accessToken = localStorage.getItem('accessToken');
            if (!accessToken) {
                console.log('No access token found, redirecting to login');
                navigate('/login', { replace: true });
                return;
            }

            console.log('Fetching episodes...');
            const response = await fetch(ENDPOINTS.EPISODES, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
                credentials: 'include',
            });
            
            console.log('Response status:', response.status);
            
            if (response.status === 401) {
                console.log('401 error detected, removing token and redirecting to login');
                localStorage.removeItem('accessToken');
                navigate('/login', { replace: true });
                return;
            }

            if (!response.ok) {
                throw new Error(`Failed to fetch episodes: ${response.status}`);
            }

            const data = await response.json();
            setEpisodes(data.episodes);
            setLoading(false);
        } catch (error) {
            console.error('Error in fetchEpisodes:', error);
            setError('Failed to load episodes. Please try again later.');
            setLoading(false);
            if (error.message.includes('Failed to fetch episodes')) {
                console.log('Fetch error, but not redirecting to login');
            }
        }
    };

    const handleRowClick = (episodeId) => {
        navigate(`/episodes/${episodeId}`);
    };

    const renderTableHeader = () => (
        <thead>
            <tr>
                <th>Episode</th>
                <th>Podcast</th>
                <th>Host</th>
                <th>Guests</th>
                <th>Duration</th>
                <th>Status</th>
            </tr>
        </thead>
    );

    const renderTableBody = () => (
        <tbody>
            {episodes.map((episode) => (
                <tr key={episode.episode_id} onClick={() => handleRowClick(episode.episode_id)}>
                    <td>{episode.episode_number}</td>
                    <td>{episode.podcast_name}</td>
                    <td>{episode.host_name}</td>
                    <td>{episode.guests_names}</td>
                    <td>{formatDuration(episode.duration)}</td>
                    <td>
                        {renderStatus(episode.generated_content_status)}
                    </td>
                </tr>
            ))}
        </tbody>
    );

    const renderStatus = (status) => (
        status ? (
            <span className="status generated">Generated</span>
        ) : (
            <span className="status pending">
                <div className="loading-circle"></div>
            </span>
        )
    );

    if (loading) return <div className="loading">Loading episodes...</div>;
    if (error) return <div className="error">{error}</div>;

    return (
        <div className="table-container">
            {episodes.length === 0 ? (
                <div className="no-data-message">
                    No episodes available. Upload your first episode to get started!
                </div>
            ) : (
                <table className="styled-table">
                    {renderTableHeader()}
                    {renderTableBody()}
                </table>
            )}
        </div>
    );
};

function formatDuration(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours}h ${minutes}m`;
}

export default TableView;